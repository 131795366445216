<template>
  <b-card class="p-2 p-lg-4 w-100">
    <b-card-title
      title-tag="h2"
      class="font-weight-bold mb-1"
    >
      Регистрация
    </b-card-title>
    <b-card-text class="mb-2">
      Заполните поля ниже
    </b-card-text>

    <!-- form -->
    <b-form
      class="auth-login-form mt-2"
      @submit.prevent
    >

      <!-- name -->
      <b-form-group
        label="Имя"
        label-for="name"
      >
        <b-form-input
          id="name"
          v-model="name"
          v-validate="'required'"
          :state="name === '' ? null : !errors.has('name')"
          name="name"
          placeholder=""
        />
        <small
          v-show="errors.has('name')"
          class="text-danger"
        >{{ errors.first('name') }}</small>
      </b-form-group>

      <!-- email -->
      <b-form-group
        label="Email"
        label-for="email"
      >
        <b-form-input
          id="email"
          v-model="email"
          v-validate="'required|email'"
          :state="email === '' ? null : !errors.has('email')"
          name="email"
          placeholder="john@example.com"
        />
        <small
          v-show="errors.has('email')"
          class="text-danger"
        >{{ errors.first('email') }}</small>
      </b-form-group>

      <!-- password -->
      <input-password
        ref="password"
        v-model="password"
        v-validate="'required|min:8'"
        label="Пароль"
        name="password"
        :state="password === '' ? null : !errors.has('password')"
        :error="errors.first('password')"
      />

      <!-- password_confirm -->
      <input-password
        v-model="password_confirm"
        v-validate="'required|confirmed:password'"
        data-vv-as="password"
        label="Подтвердить пароль"
        name="password_confirm"
        :state="password_confirm === '' ? null : !errors.has('password_confirm')"
        :error="errors.first('password_confirm')"
      />

      <!-- confirm -->
      <b-form-group class="mt-3">
        <b-form-checkbox
          id="remember-me"
          v-model="confirm"
          v-validate="'required'"
          name="confirm"
          :state="!confirm ? false : null"
        >
          Я даю согласие на обработку своих персональных данных и принимаю условия оферты
        </b-form-checkbox>
        <small
          v-show="errors.has('confirm')"
          class="text-danger"
        >{{ errors.first('confirm') }}</small>
      </b-form-group>

      <div class="d-flex align-items-center mt-1 justify-content-between">
        <div class="w-1/2">
          Уже есть аккаунт?
          <b-link :to="{name: 'login'}">
            <span>Войти</span>
          </b-link>
        </div>
        <div class="w-1/2">
          <!-- submit buttons -->
          <b-button
            class="w-auto"
            type="submit"
            variant="primary"
            block
            @click="sendForm"
          >
            Зарегистрироваться
          </b-button>
        </div>
      </div>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BLink, BFormGroup, BFormInput, BFormCheckbox, BCardText, BCardTitle, BForm, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import Auth from '@/api/http/models/auth/Auth'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
  },
  mixins: [mixinErrorsParse],
  data() {
    return {
      name: '',
      email: '',
      password: '',
      password_confirm: '',
      confirm: true,
    }
  },
  computed: {
    ...mapGetters({
      userIsVerified: 'user/isEmailVerified',
    }),
  },
  methods: {
    ...mapActions({
      fetchCurrentUser: 'user/FETCH_CURRENT_USER',
    }),
    async sendForm() {
      const validate = await this.$validator.validateAll()

      if (validate && this.confirm) {
        const response = await Auth.registration({
          name: this.name,
          email: this.email,
          password: this.password,
        })

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
        }

        if (response.status === 'success') {
          await this.fetchCurrentUser()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Добро пожаловать',
              icon: 'PersonIcon',
              variant: 'success',
            },
          })

          this.$router.push({ name: 'need-verify' })
        }
      }
    },
  },
}
</script>
